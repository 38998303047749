import React, { useEffect, useState } from "react";
import "./VideoPlayerWidget.css";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";

export default function VideoPlayerWidget({ video, playIconSize }) {
  const [playVideo, setPlayVideo] = useState(false);

  const canPlayVideo = () => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target.id === video.id) {
          if (!entry.isIntersecting) {
            entry.target.pause();
          }
        }
      });
    };

    let observer = new IntersectionObserver(callback, options);

    observer.observe(document.getElementById(video.id));
  };

  const startVideo = () => {
    setPlayVideo(true);

    setTimeout(() => {
      document.getElementById(video.id).play();
    }, 200);
  };

  useEffect(() => {
    if (playVideo) canPlayVideo();
    return () => {};
  }, [playVideo]);

  return (
    <div className="video-player-container">
      {!playVideo ? (
        <div
          style={{ position: "relative", cursor: "pointer" }}
          className="flex-container"
          onClick={startVideo}
        >
          <img src={video.thumbnail.imageUrl} alt="product video thumbnail" />
          <span className="video-play-icon flex-container">
            <PlayArrowIcon style={{ fontSize: playIconSize, color: "white" }} />
          </span>
        </div>
      ) : (
        <video
          id={video.id}
          poster={video.thumbnail.imageUrl}
          src={`${video.videoUrl}#t=0`}
          controls
        ></video>
      )}
    </div>
  );
}
