import React, { useState } from "react";
import "./ContactForm.css";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import ContactFormBgImage from "../../images/contact-form-bg.jpg";
import {
  callAction,
  validateEmail,
  validateMobileNumber,
} from "../../utils/GlobalFunctions";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";

import { FaCheckCircle } from "react-icons/fa";
import RefreshIcon from "@material-ui/icons/Refresh";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { INTERGATION_API_BASE_URL } from "../../utils/GlobalVariables";

export default function ContactForm({ project, customerId, showStatusBar }) {
  const [personName, setPersonName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");

  const [isMobileFieldFocussed, setIsMobileFieldFocussed] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);

  //-----------------------------  set input fields ---------------------------
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "mobileNumber") {
      if (value.trim().length <= 10) {
        setMobileNumber(value);
      }
    }

    if (name === "personName") {
      const regex = /\d/g;
      let normalizedName = value.replace(regex, "");

      setPersonName(normalizedName);
    }

    if (name === "email") {
      setEmail(value);
    }
  };

  const submitFormDetails = () => {
    if (email.trim() !== "" && !validateEmail(email)) {
      showStatusBar("error", "Enter a valid email Id");
      return;
    }

    setFormSubmitted(true);

    if (
      personName.trim() !== "" &&
      mobileNumber.trim().length === 10 &&
      validateMobileNumber(mobileNumber.trim())
    ) {
      setIsSubmitting(true);

      const payload = {
        name: personName.trim(),
        phoneNumber: mobileNumber.trim(),
        email: email.trim(),
        city: "",
        projectId: project.projectId,
        customerId: customerId,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify(payload), // Convert the data to JSON format
      };

      // Make the POST request
      fetch(INTERGATION_API_BASE_URL, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Could not get response from server");
          }

          return response.json(); // Parse the response as JSON
        })
        .then((responseData) => {
          // console.log("POST request successful", responseData);

          const { code, messsage } = responseData;

          setResponseStatus(code);

          // Handle the response data here
        })
        .catch((error) => {
          //console.log("There was a problem with the POST request", error);
          // Handle errors here
          showStatusBar("error", error.message);
        })
        .finally(() => {
          setFormSubmitted(false);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className="contact-form-container">
      <ProjectAssetTitle title="We'd Love to Hear from You" />
      <div style={{ height: "30px" }}></div>

      {responseStatus === "success" ? (
        <>
          <div
            className="success-msg-container flex-container"
            style={{ flexDirection: "column" }}
          >
            <FaCheckCircle style={{ color: "#557A46", fontSize: "60px" }} />
            <h3>Thank you for showing interest!</h3>
            {/* <p>
              We've received your details and
              <br /> will contact you shortly.
            </p> */}
            <p>You are just one step away from reaching us</p>
            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: "5px",
                textTransform: "capitalize",
                padding: "10px 15px",
                marginTop: "20px",
                marginBottom: "30px",
                backgroundColor: "#557A46",
                fontSize: "17px",
                width: "60%",
              }}
              onClick={(e) => {
                const contactNumber =
                  project.projectContactDetails.mobileNumbersList?.[0];

                if (contactNumber) callAction(contactNumber);
              }}
              startIcon={<PhoneIcon />}
            >
              Call Now
            </Button>
          </div>
          <Divider
            light
            variant="fullWidth"
            style={{ width: "100%", margin: "25px 0px" }}
          />
          <div className="resubmit-msg flex-container-space">
            <p>
              "Note: If you've entered incorrect contact information, simply tap
              'Resubmit'."
            </p>
            <Button
              size="small"
              variant="contained"
              color="default"
              style={{
                borderRadius: "5px",
                textTransform: "capitalize",
                padding: "5px 10px",
              }}
              onClick={(e) => setResponseStatus(null)}
              startIcon={<RefreshIcon />}
            >
              Resubmit
            </Button>
          </div>
        </>
      ) : (
        <div className="flex-container-space" style={{ flexWrap: "wrap" }}>
          <img src={ContactFormBgImage} alt="" />

          <div className="contact-form">
            <h3>Express Your Interest!</h3>
            <h6>
              Ready to explore our exciting products and services? We're eager
              to get to know you better.
            </h6>
            {/* -------------------------- person name  ----------------------------------*/}
            <TextField
              required
              autoComplete="off"
              name="personName"
              label={"Your Name"}
              variant="outlined"
              fullWidth={true}
              value={personName}
              onChange={(e) => {
                onChangeHandler(e);
              }}
              helperText={
                formSubmitted && personName.trim() === ""
                  ? "Please enter your full name"
                  : ""
              }
              error={formSubmitted && personName.trim() === ""}
              placeholder="(Eg.) Deepan Chakravarthy"
              inputProps={{ maxLength: 40 }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
                endAdornment: (
                  <span>
                    <PersonIcon style={{ color: "grey" }} />
                  </span>
                ),
              }}
            ></TextField>
            <div style={{ height: "30px" }}></div>
            {/* -------------------------- mobile number   ----------------------------------*/}
            <TextField
              id="mobile1"
              onWheel={(e) => {
                document.getElementById("mobile1").blur();
              }}
              onBlur={(e) => {
                setIsMobileFieldFocussed(false);
              }}
              onFocus={(e) => {
                setIsMobileFieldFocussed(true);
              }}
              required
              label="Mobile Number"
              variant="outlined"
              fullWidth={true}
              name="mobileNumber"
              placeholder="Enter your 10 digit number"
              type="number"
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (isMobileFieldFocussed ||
                  mobileNumber.trim() !== "") && (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
                endAdornment: (
                  <span>
                    <PhoneIcon style={{ color: "grey" }} />
                  </span>
                ),
                style: {
                  borderRadius: "10px",
                },
              }}
              onChange={(e) => {
                onChangeHandler(e);
              }}
              helperText={
                (formSubmitted && mobileNumber.trim() === "") ||
                (mobileNumber.length !== 10 && mobileNumber.trim() !== "")
                  ? "Please enter a valid mobile number"
                  : ""
              }
              error={
                (formSubmitted && mobileNumber.trim() === "") ||
                (mobileNumber.length !== 10 && mobileNumber.trim() !== "")
                  ? true
                  : false
              }
              value={mobileNumber.toString()}
            />

            {/* <div style={{ height: "30px" }}></div>

            <TextField
              label={"Email (Optional)"}
              name="email"
              value={email}
              type="email"
              onChange={(e) => {
                onChangeHandler(e);
              }}
              placeholder="xyz@gmail.com"
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              error={
                email.trim() !== "" && !validateEmail(email) ? true : false
              }
              helperText={
                email.trim() !== "" && !validateEmail(email)
                  ? "Enter valid email id"
                  : ""
              }
              variant="outlined"
              fullWidth={true}
            /> */}

            <div style={{ height: "40px" }}></div>

            <Button
              variant="contained"
              color="primary"
              style={{
                padding: "12px 10px",
                width: "100%",
                fontSize: "18px",
                borderRadius: "10px",
                textTransform: "capitalize",
                letterSpacing: "0.3px",
              }}
              onClick={submitFormDetails}
              disabled={
                isSubmitting
                // mobileNumber.trim().length !== 10 ||
                // personName.trim() === ""
              }
            >
              {isSubmitting ? (
                <CircularProgressBar size={20} />
              ) : (
                <span className="flex-container">
                  <ThumbUpIcon style={{ marginRight: "10px" }} /> I'm Interested
                  !!
                </span>
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
