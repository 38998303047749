import React from "react";
import "./ImageGalleryWidget.css";

//-----  image gallery view lib ----------------

import ImageGallery from "react-image-gallery";

//------------  material components ------------------

import IconButton from "@material-ui/core/IconButton/IconButton";

//--------  material icons --------------
import ClearIcon from "@material-ui/icons/Clear";

export default function ImageGalleryWidget({
  startIndex,
  imagesList,
  closeGalleryView,
}) {
  return (
    <div className="image-gallery-view-container flex-container">
      <ImageGallery
        startIndex={startIndex}
        items={imagesList}
        autoPlay={false}
        showThumbnails={false}
        showBullets
        showIndex
      />
      <IconButton
        onClick={(e) => {
          closeGalleryView();
        }}
        style={{
          position: "absolute",
          top: "10px",
          left: "20px",
          zIndex: 5,
          color: "white",
          backgroundColor: "rgba(54, 54, 54, 0.781)",
        }}
      >
        <ClearIcon
          style={{
            cursor: "pointer",
            fontSize: "25px",
          }}
        />
      </IconButton>
    </div>
  );
}
