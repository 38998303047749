import React from "react";
import "./NotFound.css";

import PageNotFoundImage from "../../images/page-not-found.jpg";

export default function NotFound() {
  return (
    <div className="not-found-page">
      <img src={PageNotFoundImage} alt="404" />
      {/* <h1>404</h1> */}
      <p>Page Not Found</p>
    </div>
  );
}
