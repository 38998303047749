import React from "react";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";
import "./ProjectBrochure.css";

import PDFPreviewWidget from "../PDFPreviewWidget/PDFPreviewWidget";

export default function ProjectBrochure({ assetTitle, projectBrochure }) {
  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div className="project-brochure-container">
        <PDFPreviewWidget pdfFile={projectBrochure} fileName="Brouchure" />
      </div>
    </>
  );
}
