import React from "react";
import "./LinearProgressBar.css";
import { withStyles } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ed2f59",
  },
}))(LinearProgress);

export default function LinearProgressBar({ size, color }) {
  return (
    <div>
      <BorderLinearProgress variant="indeterminate" />
    </div>
  );
}
