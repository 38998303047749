import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
  FaYoutube,
  FaTiktok,
  FaReddit,
  FaGooglePlay,
  FaAppStore,
  FaAppStoreIos,
} from "react-icons/fa";
import { MARKER_PROJECT_WEB_BASE_URL } from "./GlobalVariables";
const emailValidator = require("email-validator");

const capitalizeString = (string) => {
  return string.substring(0, 1).toUpperCase() + string.substring(1);
};

// formatting the indian mobile numbers
const formatMobileNumber = (number) => {
  if (number?.length === 10) {
    return `(+91) ${number.substring(0, 5)} ${number.substring(5)}`;
  } else {
    return number;
  }
};

const callAction = (mobileNumber) => {
  window.open(`tel:+91${mobileNumber}`, "_self");
};

const getIconOnSocialMediaLinks = (socialMedia) => {
  let icon;

  if (socialMedia === "facebook") {
    icon = (
      <FaFacebook
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "instagram") {
    icon = (
      <FaInstagram
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "twitter") {
    icon = (
      <FaTwitter
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "linkedIn") {
    icon = (
      <FaLinkedin
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "pinterest") {
    icon = (
      <FaPinterest
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "youtube") {
    icon = (
      <FaYoutube
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "tiktok") {
    icon = (
      <FaTiktok
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "reddit") {
    icon = (
      <FaReddit
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "playstore") {
    icon = (
      <FaGooglePlay
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }
  if (socialMedia === "appstore") {
    icon = (
      <FaAppStoreIos
        style={{
          color: getColorOnSocialMediaLinks(socialMedia),
          fontSize: "30px",
        }}
      />
    );
  }

  return icon;
};

const getColorOnSocialMediaLinks = (socialMedia) => {
  let color;
  if (socialMedia === "facebook") {
    color = "#1877F2";
  }
  if (socialMedia === "instagram") {
    color = "#E4405F";
  }
  if (socialMedia === "twitter") {
    color = "#1DA1F2";
  }
  if (socialMedia === "linkedIn") {
    color = "#0077B5";
  }
  if (socialMedia === "pinterest") {
    color = "#E60023";
  }
  if (socialMedia === "youtube") {
    color = "#FF0000";
  }
  if (socialMedia === "tiktok") {
    color = "#000000";
  }
  if (socialMedia === "reddit") {
    color = "#FF5700";
  }
  if (socialMedia === "playstore") {
    color = "#34A853";
  }
  if (socialMedia === "appstore") {
    color = "#007AFF";
  }

  return color;
};

const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();

  return extension === "pdf" ? "pdf" : "image";
};

function getFileExtensionFromFireStorageUrl(url) {
  const splitUrl = url.split("?")[0].split("#")[0].split("/"); // Remove query parameters and fragments and split the URL
  const filename = splitUrl.pop(); // Get the last part of the URL, which is the filename
  const filenameParts = filename.split("."); // Split the filename by periods

  if (filenameParts.length > 1) {
    return filenameParts.pop().toLowerCase(); // Get the last part, which should be the file extension
  } else {
    return ""; // No file extension found
  }
}

const getWebsiteUrl = (customerId, projectId) => {
  let websiteLink = `${MARKER_PROJECT_WEB_BASE_URL}/?pId=${projectId}`;

  return websiteLink;
};

const validateMobileNumber = (mobileNumber) => {
  const regex = /^[0-9]{10}$/g;

  return regex.test(mobileNumber.trim());
};

const validateEmail = (email) => {
  let isValid = emailValidator.validate(email.trim());

  return isValid;
};

export {
  capitalizeString,
  formatMobileNumber,
  callAction,
  getIconOnSocialMediaLinks,
  getColorOnSocialMediaLinks,
  getFileType,
  getFileExtensionFromFireStorageUrl,
  getWebsiteUrl,
  validateMobileNumber,
  validateEmail,
};
