import React from "react";
import "./ProjectLoadingWidget.css";

// --------------- images ------------
import LeedsAppLogoWithTextImage from "../../images/leedsapp-logo-red-black-text.png";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";

export default function ProjectLoadingWidget() {
  return (
    <div className="loading-spinner-container">
      <div id="content">
        <img src={LeedsAppLogoWithTextImage} alt="marker-logo" />
        <h2>M-Site</h2>
        <div className="linear-progress-bar">
          <LinearProgressBar />
        </div>
      </div>
    </div>
  );
}
