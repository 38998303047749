import React from "react";
import "./PDFPreviewWidget.css";
import { Document, Page, pdfjs } from "react-pdf";
import { isBrowser, isMobile } from "react-device-detect";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";

import BrochureImage from "../../images/brochure.png";
import QuotationImage from "../../images/quotation.png";
import { getFileExtensionFromFireStorageUrl } from "../../utils/GlobalFunctions";
import { useEffect } from "react";
import { useState } from "react";

export default function PDFPreviewWidget({ pdfFile, fileName }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [pdfPreviewImage, setPdfPreviewImage] = useState(null);
  const fileType = getFileExtensionFromFireStorageUrl(pdfFile.fileUrl);

  function onDocumentLoadSuccess({ numPages }) {
    //  setNumPages(numPages);
  }

  const pdfOptions = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
    workerSrc: "/pdf.worker.js",
  };

  const openDocument = () => {
    window.open(pdfFile.fileUrl, "_blank");
  };

  useEffect(() => {
    const convertFirstPageToImage = async () => {
      const pdf = await pdfjs.getDocument({ url: pdfFile.fileUrl }).promise;
      const page = await pdf.getPage(1); // Get the first page

      const viewport = page.getViewport({ scale: 1.0 });

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      const imageDataUrl = canvas.toDataURL("image/png");

      setPdfPreviewImage(imageDataUrl);
    };

    if (fileType === "pdf") {
      convertFirstPageToImage();
    }
  }, []);

  return (
    <>
      <div
        className="pdf-preview-container"
        onClick={openDocument}
        // style={{ backgroundColor: isMobile ? "white" : "whitesmoke" }}
      >
        {fileType === "pdf" ? (
          <>
            <img className="pdf-image" src={pdfPreviewImage} />

            {/* {isBrowser && (
              <Document
                file={pdfFile.fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                options={pdfOptions}
                onLoadError={(e) => {
                  console.log(e);
                }}
              >
                <Page pageNumber={1} />
              </Document>
            )}

            {isMobile && (
              <div className="pdf-body-content">
                <img
                  src={fileName == "Quotation" ? QuotationImage : BrochureImage}
                  alt={fileName}
                />
                <p>Tap here to download {fileName}</p>
              </div>
            )} */}

            {/* <iframe
            onClick={openDocument}
            src={pdfFile.fileUrl}
            title={fileName}
          ></iframe> */}
          </>
        ) : (
          <img className="pdf-image" src={pdfFile.fileUrl} />
        )}
        <div className="pdf-file-name flex-container">
          <PictureAsPdfIcon style={{ marginRight: "10px", color: "#F24C4C" }} />
          <p>{fileName}</p>
        </div>
      </div>
    </>
  );
}
