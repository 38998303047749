import React from "react";
import "./NoAssetsFound.css";

import PageNoAssetsFoundImage from "../../images/no-assets.jpg";

export default function NoAssetsFound() {
  return (
    <div className="no-assets-container ">
      <div className="flex-container">
        <img src={PageNoAssetsFoundImage} alt="no assets" />
      </div>
      <p>Product/Service details will be available soon</p>
    </div>
  );
}
