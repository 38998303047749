import React from "react";
import "./ContactDetails.css";

import { callAction, formatMobileNumber } from "../../utils/GlobalFunctions";

// -------------  material icons ---------------------

import CallIcon from "@material-ui/icons/Call";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import PublicIcon from "@material-ui/icons/Public";

// -------------  custom components ---------------------
import MapLocationWidget from "../MapLocationWidget/MapLocationWidget";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";

export default function ContactDetails({ assetTitle, projectContactDetails }) {
  const {
    mapLocation,
    contactName,
    websiteUrl,
    mobileNumbersList,
    emailsList,
  } = projectContactDetails;

  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div style={{ height: "20px" }}></div>

      <div className="contact-details-container">
        <div className="address">
          <h3 className="flex-container-start">
            <PersonIcon
              style={{ marginRight: "5px", fontSize: "20px", color: "#343434" }}
            />
            {contactName}
          </h3>
          {mapLocation.address.trim() !== "" && (
            <p
              className="flex-container-start"
              style={{
                alignItems: "flex-start",
                marginTop: "10px",
                color: "#343434",
              }}
            >
              <HomeIcon style={{ marginRight: "5px", fontSize: "20px" }} />
              {mapLocation.address}
            </p>
          )}

          <div className="flex-container-start">
            <CallIcon
              style={{ marginRight: "5px", fontSize: "18px", color: "green" }}
            />
            <div className="flex-container-start" style={{ flexWrap: "wrap" }}>
              {mobileNumbersList.map((item) => {
                return (
                  <p
                    key={item}
                    style={{ marginRight: "8px" }}
                    onClick={(e) => {
                      callAction(item);
                    }}
                  >
                    {formatMobileNumber(item)},
                  </p>
                );
              })}
            </div>
          </div>

          {emailsList.length > 0 && (
            <div className="flex-container-start">
              <MailIcon
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              />
              <div
                className="flex-container-start"
                style={{ flexWrap: "wrap" }}
              >
                {emailsList.map((item) => {
                  return (
                    <a
                      key={item}
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${item}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginRight: "8px" }}
                    >
                      {item},
                    </a>
                  );
                })}
              </div>
            </div>
          )}

          {websiteUrl.trim().length > 0 && (
            <div className="flex-container-start">
              <PublicIcon
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                }}
              />
              <a
                href={websiteUrl}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "8px" }}
              >
                {websiteUrl}
              </a>
            </div>
          )}
        </div>

        {mapLocation.latitude !== null && mapLocation.longitude !== null && (
          <div className="map-location">
            <MapLocationWidget
              latitude={mapLocation.latitude}
              longitude={mapLocation.longitude}
              contactName={contactName}
            />
          </div>
        )}
      </div>
    </>
  );
}
