import React, { useState } from "react";
import "./ProjectImages.css";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";

import ImageGallery from "react-image-gallery";
import ImageGalleryWidget from "../ImageGalleryWidget/ImageGalleryWidget";

export default function ProjectImages({ assetTitle, projectImages }) {
  const images = projectImages
    .filter((item) => item.imageUrl !== "")
    .map((item) => {
      return {
        original: item.imageUrl,
        thumbnail: item.imageUrl,
        name: item.name,
      };
    });

  const [previewModeOn, setPreviewModeOn] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <>
      <ProjectAssetTitle title={assetTitle} />

      <div className="project-images-container">
        {images.map((item, index) => {
          return (
            <img
              key={`IMG_${index}`}
              onClick={(e) => {
                setImageIndex(index);
                setPreviewModeOn(true);
              }}
              src={item.original}
              alt={item.name}
            />
          );
        })}
      </div>

      {previewModeOn && (
        <ImageGalleryWidget
          imagesList={images}
          startIndex={imageIndex}
          closeGalleryView={(e) => setPreviewModeOn(false)}
        />
      )}
    </>
  );
}
