import { React, useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./App.css";
import AuthUser from "./config/AuthContextAPI";
import fireApp from "./config/firebase";

// ---------  material components -------------
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";

// --------------- custom components  ------------
import ProjectLoadingWidget from "./components/ProjectLoadingWidget/ProjectLoadingWidget";
import ProjectDetails from "./screens/ProjectDetails/ProjectDetails";

// ------- firebase initialization ----------
const fireauth = fireApp.auth();
const firestore = fireApp.firestore();

let removeListener;
function App() {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  // const [user, setUser] = useState(null);
  // const [userObj, setUserObj] = useState(null);

  const [statusCode, setStatusCode] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // const listenToAuthChanges = () => {
  //   removeListener = fireauth.onAuthStateChanged((user) => {
  //     //console.log(user.uid);
  //     if (user) {
  //       setUser(user);
  //       getCustomerAndProjectDetails();
  //     } else {
  //       // User is signed out
  //       createAnonymousUser();
  //     }
  //   });
  // };

  // const createAnonymousUser = () => {
  //   setUser(null);

  //   fireauth
  //     .signInAnonymously()
  //     .then(() => {
  //       console.log("user signed in successfully");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const showStatusBar = (status, message) => {
    setStatusCode(status);
    setModalMessage(message);
    setOpenSnackBar(true);
  };

  useEffect(() => {
    const getProjectDetails = async () => {
      try {
        const urlScheme = new URLSearchParams(search);

        const projectId =
          urlScheme.get("p") ||
          urlScheme.get("pId") ||
          urlScheme.get("projectId");

        if (projectId === null) {
          throw new Error("Project Id is null");
        }

        const projectsSnapshot = await firestore
          .collectionGroup("Projects")
          .where("projectId", "==", projectId)
          .limit(1)
          .get();

        if (projectsSnapshot.docs.length > 0) {
          const projectSnapshot = projectsSnapshot.docs[0];

          const projectData = projectSnapshot.data();

          const cusId = projectSnapshot.ref.parent.parent.id;

          setCustomerId(cusId);
          setProject(projectData);

          setIsLoading(false);
        }
      } catch (error) {
        console.error("getProjectDetails error: ", error);
        showStatusBar(
          "error",
          "Failed to fetch project details. Refresh again."
        );
      }
    };

    // const ProjectFunctions_4 = fireApp
    //   .functions(CLOUD_FUNCTIONS_REGION)
    //   .httpsCallable("project_functions_group-ProjectFunctions_4");

    // ProjectFunctions_4({
    //   customerId: userId,
    //   projectId: projectId,
    // })
    //   .then((result) => {
    //     if (result.data !== null) {
    //       const { projectData, userId, code, message } = result.data;

    //       if (code === 200) {
    //         setProject(projectData);
    //         setCustomerId(userId);
    //         setIsLoading(false);
    //       }

    //       if (code === 500 || code === 501) {
    //         //    console.log(message);
    //         showStatusBar("error", message);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     //   console.log(err);
    // showStatusBar(
    //   "error",
    //   "Failed to fetch data from server. Refresh again."
    // );
    //   });

    getProjectDetails();

    return () => {};
  }, []);

  // useEffect(() => {
  //   listenToAuthChanges();

  //   return () => {
  //     removeListener?.();
  //   };
  // }, []);

  return (
    <AuthUser.Provider value={{}}>
      <div className="main-container">
        {isLoading ? (
          <ProjectLoadingWidget />
        ) : (
          <ProjectDetails
            project={project}
            customerId={customerId}
            showStatusBar={showStatusBar}
          />
        )}
      </div>

      <Snackbar
        autoHideDuration={4000}
        open={openSnackBar}
        onClose={(e) => setOpenSnackBar(false)}
        TransitionComponent={TransitionUp}
      >
        <Alert severity={statusCode}>{modalMessage}</Alert>
      </Snackbar>
    </AuthUser.Provider>
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default App;
