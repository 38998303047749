import React from "react";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";
import "./ProjectVideos.css";

import VideoPlayerWidget from "../VideoPlayerWidget/VideoPlayerWidget";

export default function ProjectVideos({ assetTitle, projectVideo }) {
  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div style={{ height: "20px" }}></div>

      <div className="project-video-container">
        <VideoPlayerWidget
          video={{ ...projectVideo, id: "productVideo" }}
          playIconSize={"50px"}
        />
      </div>
    </>
  );
}
