import React, { useState } from "react";
import "./ProjectTestimonials.css";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";
import VideoPlayerWidget from "../VideoPlayerWidget/VideoPlayerWidget";

export default function ProjectTestimonials({
  assetTitle,
  projectTestimonials,
}) {
  const testVideos = projectTestimonials
    .filter((item) => item.videoUrl !== "")
    .map((item) => {
      return {
        ...item,
        id: `TEST_VIDEO_${item.id}`,
      };
    });

  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div style={{ height: "10px" }}></div>
      <div className="project-testimonials-container">
        {testVideos.map((item, index) => {
          return (
            <VideoPlayerWidget
              key={`TEST_VIDEO_${item.id}`}
              video={item}
              playIconSize={"50px"}
            />
          );
        })}
      </div>
    </>
  );
}
