import React from "react";
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";
import "./ProjectQuotation.css";

import PDFPreviewWidget from "../PDFPreviewWidget/PDFPreviewWidget";

export default function ProjectQuotation({ assetTitle, projectQuotation }) {
  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div className="project-brochure-container">
        <PDFPreviewWidget pdfFile={projectQuotation} fileName="Quotation" />
      </div>
    </>
  );
}
