import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import "./CircularProgressBar.css";

export default function CircularProgressBar({ size, color }) {
  return (
    <div
      style={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="indeterminate"
        size={size}
        style={{ color: color !== undefined ? color : "" }}
      />
    </div>
  );
}
