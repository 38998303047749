import GoogleMapReact from "google-map-react";
import React from "react";
import "./MapLocationWidget.css";

import { capitalizeString } from "../../utils/GlobalFunctions";

export default function MapLocationWidget({
  latitude,
  longitude,
  contactName,
}) {
  const mapStyles = {
    width: "100%",
    height: "250px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
    boxShadow: "1px 3px 5px 2px rgba(10,10,10, 0.1)",
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: capitalizeString(contactName),
    });
    return marker;
  };

  return (
    <div style={mapStyles}>
      <GoogleMapReact
        options={{ scrollwheel: false }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        }}
        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}
