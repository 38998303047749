//const MARKER_PROJECT_WEB_BASE_URL = "https://192.168.1.6:3000";
const MARKER_PROJECT_WEB_BASE_URL = "https://projects.leedsapp.com";

const MARKER_WEBSITE_LINK = "https://leedsapp.com";

const INTERGATION_API_BASE_URL =
  "https://asia-south1-civil-marker-dev.cloudfunctions.net/project_website_leads_functions_group-ProjectWebsiteFunctions_1";

const CLOUD_FUNCTIONS_REGION = "asia-south1";

export {
  MARKER_PROJECT_WEB_BASE_URL,
  MARKER_WEBSITE_LINK,
  INTERGATION_API_BASE_URL,
  CLOUD_FUNCTIONS_REGION,
};
