import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import "./ProjectAssetTitle.css";

export default function ProjectAssetTitle({ title }) {
  return (
    <div className="project-asset-title">
      <div className="horizontal-line"></div>
      <h3>{title}</h3>
      <div className="horizontal-line"></div>
    </div>
  );
}
