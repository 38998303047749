import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import fireApp from "../../config/firebase";
import "./ProjectDetails.css";

// -------  material components --------
import Button from "@material-ui/core/Button";

// ------  material icons ---------
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import ShareIcon from "@material-ui/icons/Share";

// ----------  custom components ---------
import ContactDetails from "../../components/ContactDetails/ContactDetails";
import ContactForm from "../../components/ContactForm/ContactForm";
import ProjectAssetTitle from "../../components/ProjectAssetTitle/ProjectAssetTitle";
import ProjectBrochure from "../../components/ProjectBrochure/ProjectBrochure";
import ProjectImages from "../../components/ProjectImages/ProjectImages";
import ProjectQuotation from "../../components/ProjectQuotation/ProjectQuotation";
import ProjectTestimonials from "../../components/ProjectTestimonials/ProjectTestimonials";
import ProjectVideos from "../../components/ProjectVideos/ProjectVideos";
import {
  callAction,
  capitalizeString,
  getWebsiteUrl,
} from "../../utils/GlobalFunctions";
import { MARKER_WEBSITE_LINK } from "../../utils/GlobalVariables";
import NoAssetsFound from "../NoAssetsFound/NoAssetsFound";
import NotFound from "../NotFound/NotFound";

import SocialMediaLinks from "../../components/SocialMediaLinks/SocialMediaLinks";
import LEEDSAPP_LOGO_WITH_TEXT from "../../images/leedsapp-logo-red-black-text.png";

// --------  firebase initialization --------
const firedb = fireApp.database().ref();

export default function ProjectDetails({ project, customerId, showStatusBar }) {
  // const [showCallButton, setShowCallButton] = useState(true);

  const [hasAssets, setHasAssets] = useState(true);

  const checkAssetsStatus = () => {
    // console.log(project);
    let assetCount = 0;

    //let productDescription = project.description.trim();

    // if (productDescription !== "") {
    //   assetCount += 1;
    // }

    let imagesCount = project.productImagesList.filter(
      (element) => element["imageUrl"] !== ""
    ).length;

    if (imagesCount > 0) {
      assetCount += 1;
    }

    let productVideo = project.productVideo["videoUrl"];

    if (productVideo !== "") {
      assetCount += 1;
    }

    let testiVideoCount = project.testimonialsList.filter(
      (element) => element["videoUrl"] !== ""
    ).length;

    if (testiVideoCount > 0) {
      assetCount += 1;
    }

    let productBrochure = project.productBrochure["fileUrl"];

    if (productBrochure !== "") {
      assetCount += 1;
    }

    let productQuotation = project.productQuotation["fileUrl"];

    if (productQuotation !== "") {
      assetCount += 1;
    }

    const projectContactDetails = project.projectContactDetails;
    let contactName = projectContactDetails["contactName"];
    let mobileNumbersList = projectContactDetails["mobileNumbersList"];

    if (contactName.trim() !== "" && mobileNumbersList.length > 0) {
      assetCount += 1;
    }

    if (assetCount >= 1) {
      setHasAssets(true);
    } else {
      setHasAssets(false);
    }
  };

  const shareProjectDetails = async () => {
    const projectName = capitalizeString(project.title.trim());

    const projectContactDetails = project.projectContactDetails;

    const contactName = capitalizeString(
      projectContactDetails.contactName.trim()
    );

    const mobileNumbersList = projectContactDetails.mobileNumbersList;

    const formattedContactsList = mobileNumbersList
      .map((item) => `+91 ${item}`)
      .join(", ");

    const websiteUrl = getWebsiteUrl(customerId, project.projectId);

    // this is for media content - image
    const projectImageItem = project.productImagesList[0];
    const projectImageUrl = projectImageItem["imageUrl"];

    const shareText = `Hi 👋,\n\nI hope you're having a great day! 😊 \n\nTo know more about our ${project.projectType.toLowerCase()} click the link below.\n\n👉 *${projectName}*\n${websiteUrl}\n\nFor more details, Contact us 🤝\n\n👤 Contact Name: ${contactName}\n📞 Mobile Number: ${formattedContactsList}\n\nLooking forward to hearing from you. Have a fantastic day! 🌞\n\nBest regards,\n*${contactName}*`;

    // const shareText = `Hi 👋,\n\nI hope you're having a great day! 😊 \n\nI wanted to share something exciting with you. We have a new project in the works, and I thought you might be interested in checking out the details.\n\nYou can explore all the project specifics by clicking on the link below:\n\n👉 *${projectName}*\n${websiteUrl}\n\nIf you have any questions, need additional information, or simply want to chat about it, please don't hesitate to reach out. I'm here to help! 🤝\n\n👤 Contact Name: ${contactName}\n📞 Mobile Number: ${formattedContactsList}\n\nFeel free to take a look whenever you have a moment. I'd love to hear your thoughts on it!\n\nLooking forward to hearing from you. Have a fantastic day! 🌞\n\nBest regards,\n*${contactName}*`;

    // navigator.share is not availble in desktop, only available in mobile/tab
    if (navigator.share) {
      // let imageFile;

      // const response = await fetch(projectImageUrl);

      // if (response.ok) {
      //   const blobObj = response.blob();

      //   // 3. Create a new File object from the Blob
      //   imageFile = new File([blobObj], "share-image.jpg", {
      //     type: blobObj.type, // Set the MIME type of the image (optional)
      //     lastModified: new Date().getTime(), // Set the last modified timestamp (optional)
      //   });
      // }

      // console.log(imageFile);

      navigator
        .share({
          title: "Welcome to LeedsApp Community!!",
          text: shareText,
        })
        .then(async () => {
          // console.log("Shared successfully");
          await incrementShareCount();
        })
        .catch((error) => {
          // console.error("Error sharing:", error);
        });
    } else {
      window.open(
        `whatsapp://send?text=${encodeURIComponent(shareText)}`,
        "_self"
      );

      await incrementShareCount();
    }
  };

  // increase project shares count in server
  const incrementShareCount = async () => {
    await firedb
      .child("ProjectStats")
      .child(customerId)
      .child(project.projectId)
      .child("sharesCount")
      .transaction(
        (value) => {
          return value + 1;
        },
        async (err, status, data) => {
          if (err) {
            //  console.log(err);
            // showStatusBar(
            //   "error",
            //   "Failed to delete settlement history. Please try again."
            // );
          }
        }
      );
  };

  // increase project site visits count in server
  const incrementSiteVisitCount = async () => {
    await firedb
      .child("ProjectStats")
      .child(customerId)
      .child(project.projectId)
      .child("siteVisitsCount")
      .transaction(
        (value) => {
          return value + 1;
        },
        async (err, status, data) => {
          if (err) {
            // console.log(err);
            // showStatusBar(
            //   "error",
            //   "Failed to delete settlement history. Please try again."
            // );
          }
        }
      );
  };

  useEffect(() => {
    if (project !== null) {
      checkAssetsStatus();
    }

    incrementSiteVisitCount();
    return () => {};
  }, []);

  // const canShowCallButton = () => {
  //   let options = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 0.1,
  //   };

  //   let callback = (entries, observer) => {
  //     entries.forEach((entry) => {
  //       if (entry.target.id === "contact-us") {
  //         if (entry.isIntersecting) {
  //           setShowCallButton(false);
  //         } else {
  //           setShowCallButton(true);
  //         }
  //       }
  //     });
  //   };

  //   let observer = new IntersectionObserver(callback, options);

  //   observer.observe(document.getElementById("contact-us"));
  // };

  // useEffect(() => {
  //   if (project !== null) {
  //     canShowCallButton();
  //   }

  //   return () => {};
  // }, []);

  const contactNumber = project.projectContactDetails.mobileNumbersList?.[0];

  return (
    <div className="center-content">
      {project !== null ? (
        <div className="project-details-container">
          <div style={{ padding: "0px 20px" }}>
            <div className="flex-container">
              <h2>
                {project.title}
                {/* <b>Powered by LeedsApp</b> */}
              </h2>
            </div>
            {/* <Divider style={{ marginTop: "10px", marginBottom: "10px" }} /> */}

            <div style={{ height: "30px" }}></div>

            {/* --------------- project description ----------------- */}
            {project.description.trim() !== "" && (
              <>
                <ProjectAssetTitle title={`About the ${project.projectType}`} />
                <p className="project-desription">{project.description}</p>
                {/* {hasAssets && (
                <>
                  <div style={{ height: "30px" }}></div>
                  <ShareButton project={project} />
                </>
              )} */}
              </>
            )}

            {!hasAssets ? (
              <NoAssetsFound />
            ) : (
              <>
                {project.assetInfoList.map((assetItem) => {
                  let assetComponent;
                  switch (assetItem["id"]) {
                    case "productImages":
                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {project.productImagesList.filter(
                            (item) => item.imageUrl !== ""
                          ).length > 0 && (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <ProjectImages
                                assetTitle={assetItem["name"]}
                                projectImages={project.productImagesList}
                              />
                            </>
                          )}
                        </div>
                      );
                      break;
                    case "productVideo":
                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  project videos ----------------- */}
                          {project.productVideo.videoUrl !== "" && (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <ProjectVideos
                                assetTitle={assetItem["name"]}
                                projectVideo={project.productVideo}
                              />
                              <div style={{ height: "20px" }}></div>
                            </>
                          )}
                        </div>
                      );
                      break;
                    case "testimonialVideos":
                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  testimonial videos ----------------- */}
                          {project.testimonialsList.filter(
                            (item) => item.videoUrl !== ""
                          ).length > 0 && (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <ProjectTestimonials
                                assetTitle={assetItem["name"]}
                                projectTestimonials={project.testimonialsList}
                              />
                              <div style={{ height: "20px" }}></div>
                            </>
                          )}
                        </div>
                      );
                      break;
                    case "productBrochure":
                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  project brouchure ----------------- */}
                          {project.productBrochure.fileUrl !== "" && (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <ProjectBrochure
                                assetTitle={assetItem["name"]}
                                projectBrochure={project.productBrochure}
                              />
                            </>
                          )}
                        </div>
                      );
                      break;
                    case "productQuotation":
                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  project quotation ----------------- */}
                          {project.productQuotation.fileUrl !== "" && (
                            <>
                              <div style={{ height: "40px" }}></div>
                              <ProjectQuotation
                                assetTitle={assetItem["name"]}
                                projectQuotation={project.productQuotation}
                              />
                            </>
                          )}
                        </div>
                      );
                      break;
                    case "projectContactDetails":
                      let hasContactDetails =
                        project.projectContactDetails.contactName.trim() !==
                          "" &&
                        project.projectContactDetails.mobileNumbersList.length >
                          0;

                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  map location ----------------- */}
                          {hasContactDetails && (
                            <div id="contact-us">
                              <div style={{ height: "40px" }}></div>
                              <ContactDetails
                                assetTitle={assetItem["name"]}
                                projectContactDetails={
                                  project.projectContactDetails
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                      break;

                    case "socialMediaLinks":
                      const socialMediaLinks = project.socialMediaLinks;

                      const hasSocialLinks =
                        socialMediaLinks.facebook != null ||
                        socialMediaLinks.instagram != null ||
                        socialMediaLinks.twitter != null ||
                        socialMediaLinks.youtube != null ||
                        socialMediaLinks.tiktok != null ||
                        socialMediaLinks.linkedIn != null ||
                        socialMediaLinks.reddit != null ||
                        socialMediaLinks.pinterest != null ||
                        socialMediaLinks.playstore != null ||
                        socialMediaLinks.appstore != null;

                      assetComponent = (
                        <div key={assetItem["id"]}>
                          {/* ---------------  map location ----------------- */}
                          {hasSocialLinks && (
                            <div>
                              <div style={{ height: "40px" }}></div>
                              <SocialMediaLinks
                                assetTitle={assetItem["name"]}
                                socialMediaLinks={project.socialMediaLinks}
                              />
                            </div>
                          )}
                        </div>
                      );
                      break;

                    default:
                      assetComponent = <div key={assetItem["id"]}></div>;
                  }

                  return assetComponent;
                })}

                <div style={{ height: "50px" }}></div>

                <ContactForm
                  project={project}
                  customerId={customerId}
                  showStatusBar={showStatusBar}
                />
              </>
            )}
          </div>

          <div style={{ height: "100px" }}></div>

          {hasAssets && (
            <div
              className="flex-container-space sticky-action-buttons"
              style={{ position: isBrowser ? "sticky" : "fixed" }}
            >
              {contactNumber && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#539165",
                      fontSize: "17px",
                      flex: 1,
                      height: "50px",
                      borderRadius: "8px",
                    }}
                    disableElevation
                    startIcon={
                      <span id="call-icon">
                        <PhoneInTalkIcon style={{ fontSize: "22px" }} />
                      </span>
                    }
                    onClick={(e) => {
                      callAction(contactNumber);
                    }}
                  >
                    Call Us
                  </Button>
                  <div style={{ width: "15px" }}></div>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#1778F2",
                  height: "50px",
                  flex: 1,
                  fontSize: "17px",
                  borderRadius: "8px",
                }}
                disableElevation
                startIcon={<ShareIcon style={{ fontSize: "22px" }} />}
                onClick={shareProjectDetails}
              >
                Share
              </Button>
            </div>
          )}
          <Footer />
          {hasAssets && <div style={{ height: "70px" }}></div>}

          {/* <div className="call-button">
            <Zoom
              in={showCallButton}
              timeout={{
                enter: 300,
                appear: 200,
                exit: 300,
              }}
              style={{
                transitionDelay: "100ms",
              }}
              unmountOnExit
            >
              <Fab
                onClick={callAction}
                color="primary"
                style={{ backgroundColor: "#539165" }}
                size="large"
              >
                <PhoneIcon style={{ fontSize: "25px", color: "white" }} />
              </Fab>
            </Zoom>
          </div> */}
        </div>
      ) : (
        <>
          <NotFound />
          <Footer />
        </>
      )}
    </div>
  );
}

const Footer = () => {
  const gotoLeedsAppWebsite = () => {
    window.open(MARKER_WEBSITE_LINK, "_blank");
  };

  return (
    <div className="project-footer" onClick={gotoLeedsAppWebsite}>
      <p className="flex-container">
        This site is powered by <img src={LEEDSAPP_LOGO_WITH_TEXT} />
      </p>
    </div>
  );
};
