import firebase from "firebase";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

//------------ development ------------
// firebase.initializeApp(firebaseConfig).messaging().getToken(
//   // Project Settings => Cloud Messaging => Web Push certificates
//   {
//     vapidKey:
//       "BIxI4OqMv0Cd7ootGe3wph_fsN2PblGGVg6NiUzOl6jPbMY8WdYEOYNd8tNNL-fWO2G-wzBYtK9YzgTu8uAIxq4",
//   }
// );

// //------------ production ------------
// firebase.initializeApp(firebaseConfig).messaging().getToken(
//   // Project Settings => Cloud Messaging => Web Push certificates
//   {
//     vapidKey:
//       "BA_AHeX3uEtfnvIpEKUHpdA1gSQOJAng1vS0130Il40-fFcyxi3Pa0FBHWu_LecGoJEhc92eM52M_7ZupOHMsgQ",
//   }
// );

export default !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
