import React from "react";
import "./SocialMediaLinks.css";
import AuthUser from "../../config/AuthContextAPI";

import {
  capitalizeString,
  getIconOnSocialMediaLinks,
} from "../../utils/GlobalFunctions";

// -------------  material components ---------------------
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// -------------  custom components ---------------------
import ProjectAssetTitle from "../ProjectAssetTitle/ProjectAssetTitle";

export default function SocialMediaLinks({ assetTitle, socialMediaLinks }) {
  const gotoSocialMediaLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <ProjectAssetTitle title={assetTitle} />
      <div style={{ height: "10px" }}></div>

      <div className="social-media-links-container">
        {Object.entries(socialMediaLinks).map(
          ([socialMediaId, link], index) => {
            return (
              link !== null && (
                <div
                  className="social-link-item"
                  key={`${socialMediaId}_${index}`}
                >
                  <ListItem
                    button={true}
                    onClick={(e) => {
                      gotoSocialMediaLink(link);
                    }}
                  >
                    <ListItemIcon>
                      {getIconOnSocialMediaLinks(socialMediaId)}
                    </ListItemIcon>
                    <ListItemText
                      primary={capitalizeString(socialMediaId)}
                      secondary={link}
                    />

                    {/* <a
                      key={socialMediaId}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ padding: "0px 10px", margin: "10px 0px" }}
                    ></a> */}
                  </ListItem>
                </div>
              )
            );
          }
        )}
      </div>
    </>
  );
}
